import React from "react"

import Product from "../../utils/templates/ProductTemplate"
import productsList from "../../data/Products.json"

const Products = () => {
  return (
    <div className="products">
      <div className="auxdiv" id="products"></div>
      <div className="section">
        <h2>PRODUCTS</h2>
      </div>
      <div>
        {productsList.map(function (product, index) {
          return <Product index={index} />
        })}
      </div>
    </div>
  )
}

export default Products
