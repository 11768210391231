import React from "react"

import HamburgerButton from "../HamburgerButton"
import Overlay from "./Overlay"


const Navbar = ({ page }) => {    
    // let isActive = React.useState(false)
    const [isVisible, setVisibility] = React.useState(false);
    const toggleMenu = () => {
        let title = document.getElementsByClassName("title")[0]
        setVisibility(!isVisible)
        let visible = document.getElementsByClassName("overlay")[0].style.display
        if(visible != "flex" || visible == "") { 
            document.getElementsByClassName("overlay")[0].style.display = "flex"
            // document.getElementsByClassName("title")[0].classList.add("hide")
        }
        else {
            document.getElementsByClassName("overlay")[0].style.display = "none"
            // document.getElementsByClassName("title")[0].classList.remove("hide")
        }
    }
    return(
        <nav className="navbarMobile">
            <HamburgerButton onClick={ toggleMenu } active={ isVisible } type="slider" />
            <Overlay  page={ page } />
        </nav>
    )
}

export default Navbar