import React from "react";


const Overlay = ({ visible, page }) => {
  if (page == "") {
    return (
      <div className="overlay noscroll">
        <ul className="tabsMobile">
          <li className="tabMobile active">
            <a href="/#home">HOME</a>
          </li>
          <li className="tabMobile">
            <a href="/#products">PRODUCTS</a>
          </li>
          <li className="tabMobile">
            <a href="/#about">ABOUT US</a>
          </li>
          {/*<li className="tabMobile">
            <a href="#locations">LOCATIONS</a>
          </li>*/}
          {/* <li className="tabMobile"><a href="#">CAREER</a></li> */}
          <li className="tabMobile">
            <a href="/#contact">CONTACT US</a>
          </li>
          {/*<li className="tabMobile">
            <a href="#blog">BLOG</a>
          </li>*/}
          <li className="tabMobile">
            <a href="tel:+917984489771" className="phone">
              +91-7984489771
            </a>
          </li>
        </ul>
      </div>
    )
  } else if (page == "products") {
    return (
      <div className="overlay noscroll">
        <ul className="tabsMobile">
          <li className="tabMobile active">
            <a href="#home">HOME</a>
          </li>
          <li className="tabMobile">
            <a href="#features">FEATURES</a>
          </li>
          <li className="tabMobile">
            <a href="#testimonials">TESTIMONIALS</a>
          </li>
          <li className="tabMobile">
            <a href="#locations">LOCATIONS</a>
          </li>
          <li className="tabMobile">
            <a href="#gallery">GALLERY</a>
          </li>
          <li className="tabMobile">
            <a href="#faqs">FAQ'S</a>
          </li>
        </ul>
      </div>
    )
  }
}

export default Overlay
