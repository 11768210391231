import React from "react"

import products from "../../data/Products.json"

const ContactUs = () => {
  return (
    <div className="contactUs">
      <div className="auxdiv" id="contact"></div>
      <div className="section">
        <h2>CONTACT US</h2>
      </div>
      <div className="contactFormTitle">
        <h1>INTERESTED IN OUR PRODUCTS?</h1>
        <br></br>
        <h3>We’re always there to assist you</h3>
        <h3>
          Please fill the form below and our team will get in touch right away
        </h3>
      </div>
      <form className="contactForm" name="contactForm" data-netlify="true" netlify>
        <input type="hidden" name="form-name" value="contactForm" />
        <input type="text" name="name" placeholder="Name*" required />
        <input type="text" name="country" placeholder="Country*" required />
        <input type="text" name="state" placeholder="State" />
        <input type="email" name="email" placeholder="Email*" required />
        <input type="text" name="phone" placeholder="Phone No." pattern="^((\+)?(\d{2}[-]))?(\d{10}){1}?$" required/>
        <select name="productName">
          <option>Choose Product</option>
          {products.map(function (product, index) {
            return <option>{product.productName}</option>
          })}
        </select>
        <textarea type="text" name="message" placeholder="Message*" required />
        <button type="submit" className="submitButton">
          SUBMIT<span></span>
        </button>
      </form>
    </div>
  )
}

export default ContactUs
