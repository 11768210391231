import React from 'react';
import { Link } from 'gatsby';


const footer = () => (
    <div className="footer">
        <div className="row">
            <div className="col col3">
                <h4>ADDRESS</h4>
                <ul className="list">
                    <li><a href="mailto:contact@futureofgaming.tech">contact@futureofgaming.tech</a></li>
                    <li><a href="tel:+917984489771">+91-7984489771</a></li>
                    <li>
                        <a 
                            href="https://g.co/kgs/TqFyfJo" 
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                            D-203, Aagam Shopping World<br></br>Vesu, Surat, India - 395007
                        </a>
                    </li>
                </ul>
            </div>
            <div className="col col2">
                <h4>FOLLOW</h4>
                <div class="footeri">
                    {/* <a href="https://www.facebook.com/laserwarsindia" target="_blank"><i class="fa fa-facebook"></i></a> */}
                    <a href="https://www.instagram.com/fogfutureofgaming/" target="_blank"><i class="fa fa-instagram"></i></a>
                    <a href="https://www.youtube.com/@FOGFutureOfGaming" target="_blank"><i class="fa fa-youtube-play" aria-hidden="true"></i></a>
                    <a href="https://www.linkedin.com/company/fog-technologies/" target="_blank"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
                </div>
            </div>
            <div className="col col4">
                <h4>POLICIES</h4>
                <ul>
                  <li>
                    <a target="_blank" href="https://docs.google.com/document/d/e/2PACX-1vQ6briqozlW944FGvUL1KH0WDk1mW7UhQ5LKZ2TIQVkidOqHXZWQbPTAjK73N3lrCvVHl46rwkSQ0vr/pub">Privacy Policy</a>
                  </li>
                  <li>
                    <a target="_blank" href="https://docs.google.com/document/d/e/2PACX-1vRDRyQ2GyZjpy5ASofjQomnXahXhFZa4NyBWWBInNjH9zLruTbL_I-3wtlf3-8xE2KGfynGbWStFSAm/pub">Terms and Conditions</a>
                  </li>
                  {/* <li>
                    <a target="_blank" href="https://docs.google.com/document/d/e/2PACX-1vR8CDkbjWsmn62EbR8tEJiNjYOQcAex_SPws915y1c3uXHYwAWSOhAM4F-34XgFcqSfLejEk7cVinFM/pub">Cancellation/Refund Policies</a>
                  </li> */}
                </ul>
            </div>
            <div className="col col1">
                <h4>LINKS</h4>
                <ul>
                    <li><a href="/">HOME</a></li>
                    <li><a href="/#products">PRODUCTS</a></li>
                    <li><a href="/#about">ABOUT US</a></li>
                    {/* <li><a href="">LOCATIONS</a></li> */}
                    <li><a href="/careers">CAREERS</a></li>
                    {/* <li><a href="/blog">BLOGS</a></li> */}
                    <li><a href="/#contact">CONTACT US</a></li>
                </ul>
            </div>

            <div className="cpryt">
                <p>© COPYRIGHT 2024. ALL RIGHTS RESERVED.</p>
            </div>
        </div>
    </div>
);

export default footer;
