import React from "react"

const About = () => {
  return (
    <div className="about">
      <div className="auxdiv" id="about"></div>
      <div className="section">
        <h2>ABOUT US</h2>
      </div>
      <p>
        {/*<span className="quote">&quot; </span>*/}
        FOG - Future of Gaming is a rapidly growing company
        founded by IIT Delhi & IIT Mumbai Alumni. As a passionate, young team we
        focus on creating next-gen gaming technologies that are unique,
        engaging and fun for all age groups. We are not just a team of
        designers, inventors, and innovators, but we are a team of DREAMERS.
        {/*<span className="quote">&quot; </span>*/}
      </p>
      <ul className="mainpoints">
        <li>
          <div className="p-head">
            <h3>TECHNICAL EXPERTISE</h3>
          </div>
          <div className="p-image">
            <img src="icons/brain-circuit.png"></img>
          </div>
          <div className="p-content">
            <span>
              Our products are built with high quality standards and deep
              understanding of user requirements. We engineer each component of the product with extreme
              details and match international engineering standards.
            </span>
          </div>
        </li>
        <li>
          <div className="p-head">
            <h3>CUSTOMER EXPERIENCE</h3>
          </div>
          <div className="p-image">
            <img className="invert" src="icons/happy-customer.png"></img>
          </div>
          <div className="p-content">
            <span>
              Customers are at the heart of our product design. We believe in
              providing each customer an ultimate WOW experience during the
              game. Not sure or find it hard to believe? Let's take a bet on it.
            </span>
          </div>
        </li>
        <li>
          <div className="p-head">
            <h3>MINIMUM MAINTENANCE</h3>
          </div>
          <div className="p-image">
            <img className="invert" src="icons/low-maintenance.png"></img>
          </div>
          <div className="p-content">
            <span>
              Worried about downtime or breakdown?<br></br>We research, develop
              and test each part of our product in-house. Additionally, our
              fail-safe algorithms make each game extremely reliable.
            </span>
          </div>
        </li>
        <li>
          <div className="p-head">
            <h3>FAST PROFITS</h3>
          </div>
          <div className="p-image">
            <img className="invert" src="icons/money.png"></img>
          </div>
          <div className="p-content">
            <span>
              Our attractions have a demonstrated history of generating high
              revenue per square foot for our clients. They are architectured to
              be fun, engaging, addictive, and compatible for all age groups.
            </span>
          </div>
        </li>
        <li>
          <div className="p-head">
            <h3>MADE IN INDIA</h3>
          </div>
          <div className="p-image">
            <img src="icons/make-in-india.png"></img>
          </div>
          <div className="p-content">
            <span>
              We are proud to be a part of "Make in India" movement, which is
              designed to foster innovation, enhance skill development, protect
              IP and build best in class infrastructure in the country.
            </span>
          </div>
        </li>
      </ul>
    </div>
  )
}

export default About
